<template>
    <div class="card-m" 
         :class="{ 'first-of-author': isFirstMessageAuthor, 'negative-sentiment': parseFloat(sentiment) < 0 }">
        <b-avatar v-if="isFirstMessageAuthor" :src="avatar" size="1.8rem" class="avatar-m"></b-avatar>
        <div class="header-m">
            <span class="number-m mr-1" :style="{ color: color }">{{ name }}</span>
            <span class="number-m">{{ number }}</span>
        </div>
        <AudioPlayer v-if="type == 'ptt'" :srcAudio="path"></AudioPlayer>
        <img v-if="type == 'image' || type == 'sticker'" :src="path" class="img-m">
        <span v-html="markdownContent(linkedContent.split('\n').join('<br>'))"></span>
        <span class="hora-m">{{ date }}</span>
    </div>
</template>

<script>
import AudioPlayer from "./AudioPlayer.vue";

export default {
    components: {
        AudioPlayer
    },
    props: ['name', 'content', 'number', 'date', 'isFirstMessageAuthor', 'avatar', 'color','sentiment','type','path'],
    computed: {
        linkedContent() {
            return this.content.replace(
                /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
                '<a href="$1" target="_blank">$1</a>'
            );
        },
    },
    methods: {
        markdownContent(content) {
            return content
                .replace(/\*([^*]+)\*/g, '<b>$1</b>')
                .replace(/_([^_]+)_/g, '<i>$1</i>')
                .replace(/~([^~]+)~/g, '<strike>$1</strike>')
                .replace(/`([^`]+)`/g, '<code>$1</code>');
        }
    }
};
</script>

<style scoped>
.card-m {
  background: var(--neutros-700);
  border-radius: 5px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  color: var(--neutros-200);
  font-size: 13px;
  font-weight: 300;
  width: fit-content;
  margin-top: 8px;
  box-shadow: 0px 2px 0.5px rgb(11 20 26 / 28%);
  max-width: 75%;
  word-break: break-word;
}

.avatar-m {
  position: absolute;
  top: 0;
  left: -38px;
}

.first-of-author {
  border-radius: 0 5px 5px 5px;
  margin-top: 20px;
  position: relative;
}

.hora-m, .number-m {
  font-size: 11px;
  color: var(--neutros-400);
}

.number-m {
  margin-bottom: 3px;
}

.header-m {
  display: none;
}

.first-of-author /deep/ .header-m {
  display: block !important;
  margin-top: -5px;
  margin-bottom: 4px;
}

.img-m{
  max-width: 250px;
  height: auto;
  border-radius: 2px;
  margin-bottom: 6px;
}

.negative-sentiment{
  border: 1px solid red;
}
</style>
