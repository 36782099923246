import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../pages/LoginPage'
import UserProfile from "../pages/UserProfile"
import Home from "../pages/HomePage"
import GroupMonitoringPage from "../pages/GroupMonitoringPage"
import { auth } from '../firebase' // Importando corretamente

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', component: LoginPage, meta: { redirectIfLogged: true } },
    {
        path: "/profile",
        name: "Profile",
        component: UserProfile,
    },
    {
        path: "/monitoramento-grupos",
        name: "GroupMonitoring",
        component: GroupMonitoringPage,
        meta: { requiresAuth: true },
    },
    {
        path: "/home",
        name: "HomePage",
        component: Home,
        meta: { requiresAuth: true },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const redirectIfLogged = to.matched.some(record => record.meta.redirectIfLogged);
    const isAuthenticated = !!auth.currentUser;

    if (requiresAuth && !isAuthenticated) {
        next('/login');
    } else if (isAuthenticated && redirectIfLogged) {
        next('/home');
    } else {
        next();
    }
});

export default router;