<template>
  <PageWrapper pageTitle="Monitoramento dos Grupos">
    <b-container fluid class="mh-100 p-4 container-chat sob">
      <b-row class="flex-nowrap mh-100 m-0">
        <b-col cols="4" class="p-0">
          <GroupList @toGrandParent="trasnferFetchGroupMessages"></GroupList>
        </b-col>
        <b-col cols="8" class="p-0">
          <MessageList :selectedGroupss="selected" ></MessageList>
        </b-col>
      </b-row>
    </b-container>
  </PageWrapper>
</template>

<script>
import PageWrapper from "../components/PageWrapper.vue";
import GroupList from "../components/GroupList.vue";
import MessageList from "../components/MessageList.vue";


export default {
  components: {
    PageWrapper,
    MessageList,
    GroupList
  },
  data(){
    return{
      selected:null
    }
  },
  methods: {
    async trasnferFetchGroupMessages(valueFromNeto){
      this.selected = valueFromNeto
    },
  },
};
</script>

<style scoped>



</style>
