<template>
  <div class="sidebar" style="padding-top:16px">
        <div class="menu-item first">
      <b-link to="/home">
        <img src="../assets/logo.svg">
      </b-link>
    </div>
    <div class="menu-item">
      <b-link to="/home">
        <b-icon icon="house"  font-scale="1.5"></b-icon>
      </b-link>
    </div>
    <div class="menu-item">
      <b-link to="/monitoramento-grupos">
        <b-icon-chat-square-text font-scale="1.5"></b-icon-chat-square-text>
      </b-link>
    </div>
    <div class="menu-item">
      <b-link to="/clientes">
        <b-icon-people font-scale="1.5"></b-icon-people>
      </b-link>
    </div>
    <div class="menu-item">
      <b-link to="/conquistas">
        <b-icon-trophy font-scale="1.5"></b-icon-trophy>
      </b-link>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
}
</script>


<style scoped>
.sidebar {
    width: 79px;
    background-color: var(--neutros-900);
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.menu-item {
  padding: 4px;
  color: white;
  margin-bottom: 24px;
}

.first{
    margin-bottom: 48px;
}

.menu-item /deep/ a{
    color: var(--neutros-600)
}

a:hover{
    color: var(--neutros-400)
}
</style>
