// src/main.js
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { auth } from './firebase'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import InfiniteLoading from 'vue-infinite-loading';
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(InfiniteLoading);


Vue.config.productionTip = false

auth.onAuthStateChanged(() => {
    new Vue({
        router,
        render: h => h(App)
    }).$mount('#app')
})