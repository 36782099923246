<template>
  <div>


    <b-container fluid class="p-0">
      <b-row class="mr-0">
        <b-col cols="auto" class="sidebar-placeholder">
          <SideBarMenu/>
        </b-col>

        <b-col class="d-flex flex-column justify-content-start vh-100 main">
          <b-row class="align-items-center p-4">
            <b-col>
              <h1 class="title-head-bar">{{ pageTitle }}</h1>
            </b-col>

            <b-col class="d-flex flex-row justify-content-end align-items-center text-right lateral-user ">
              <p v-if="user" style="color:var(--neutros-200); font-weight:300; font-size:13px">{{ user.username }}</p>
              <b-skeleton v-else class="w-25" ></b-skeleton>

              <b-dropdown variant="link" class="ml-3 dropdown-avatar" no-caret right>
                <template #button-content>
                  <b-avatar v-if="user" :src="avatar"></b-avatar>
                  <b-skeleton v-else type="avatar"></b-skeleton>
                </template>

                <b-dropdown-item @click="logout">Logout</b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>

          <b-row  class="flex-column overflow-auto p-5 ">
            <slot></slot>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import { getAuth, onAuthStateChanged, getIdToken, signOut } from "firebase/auth";
import router from '@/router';
import SideBarMenu from './SideBarMenu';


export default {
  props: ['pageTitle'],
  data() {
    return {
      user: null,
      avatar: 'a',
      token: null,
      roles: [], // Armazena as roles do usuário
    };
  },
    components: {
    SideBarMenu,
    // outros componentes que você esteja usando
  },
  
  async mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        this.token = await getIdToken(currentUser);
        await this.getDiscordUser();
      } else {
        // usuário não está logado
      }
    });
  },
  methods: {
    async getDiscordUser() {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/api/user', {
          headers: {  
            'Authorization': `Bearer ${this.token}`
          }
        });
        this.user = response.data
        this.avatar = `https://cdn.discordapp.com/avatars/${response.data.discordId}/${response.data.avatar}.png`

        console.log(`https://cdn.discordapp.com/avatars/${response.data.discordId}/${response.data.avatar}.png`);
        console.log(response)
      } catch (error) {
        console.error(error);
      }
    },
    logout() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.user = null;
        this.avatar = '';
        this.token = null;
        this.roles = []; // Limpar roles ao sair
        router.push('/login');
      }).catch((error) => {
        console.error(error);
      });
    },
    navigate(routeName) {
      this.$router.push({ name: routeName });
    }
  }
};
</script>

<style scoped>

.dropdown-avatar /deep/ button{
  padding: 0
}

.lateral-user p{
  margin: 0;
}
.title-head-bar{
  font-size: 19px;
  font-weight: 300;
  color: var(--neutros-200);
  margin: 0;
  letter-spacing: 1px;
}
.sidebar-placeholder {
  width: 94px;
}

.main{
  background-image: url("../assets/mainbackground.jpg");
}
</style>
