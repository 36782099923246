// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyDkP_Nj563K2mKnNOjpP_OXoAVBqkefuQE",
    authDomain: "financial-88bcc.firebaseapp.com",
    projectId: "financial-88bcc",
    storageBucket: "financial-88bcc.appspot.com",
    messagingSenderId: "641683797321",
    appId: "1:641683797321:web:11b99ba11611a1ed2c1698"
};

// Inicializa o Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Exporta os serviços:
export const db = getDatabase(firebaseApp);
export const auth = getAuth(firebaseApp);