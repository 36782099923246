<template>
  <div id="app">
    <div class="scroll-container">
      <ul>
        <li v-for="message in messages" :key="message.id">{{ message.text }}</li>
      </ul>
      <infinite-loading @infinite="loadMore"></infinite-loading>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    InfiniteLoading
  },
  data() {
    return {
      messages: [],
      nextCursor: null,
      groupId: '120363127931361583@g.us', // replace with your group id
      limit: 8 // replace with your preferred page size
    };
  },
  methods: {
    async loadMore($state) {
      try {
        const response = await axios.get(`http://localhost:3000/api/groups/${this.groupId}`, {
          params: {
            limit: this.limit,
            startAfterDate: this.nextCursor
          }
        });

        if (response.status === 200) {
          const { messages, nextCursor, hasNextPage } = response.data;
          
          this.messages = [...this.messages, ...messages];
          this.nextCursor = nextCursor;

          if (!hasNextPage) {
            $state.complete();
          } else {
            $state.loaded();
          }
        } else {
          $state.error();
        }
      } catch (error) {
        console.error('Error loading more messages:', error);
        $state.error();
      }
    }
  }
};
</script>

<style scoped>
.scroll-container {
  max-height: 200px;
  overflow-y: auto;
}
</style>
