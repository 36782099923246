<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <b-card style="max-width: 480px; width: 100%" class="mx-auto">
      <b-card-title class="text-center">
        <h2 class="login-title">Login</h2>
      </b-card-title>
      <b-alert variant="danger" v-model="loginError" dismissible>
        Falha na autenticação. Verifique seu e-mail e senha.
      </b-alert>
      <b-form @submit.prevent="login">
        <b-form-group label="E-mail" label-for="email-input">
          <b-form-input v-model="email" id="email-input" type="email" required></b-form-input>
        </b-form-group>
        <b-form-group label="Senha" label-for="password-input">
          <b-form-input v-model="password" id="password-input" type="password" required></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" block :disabled="isLoading">
          <b-spinner small v-if="isLoading"></b-spinner>
          <span v-else>Login</span>
        </b-button>
      </b-form>
      <div class="text-center mt-3">
        <b-button variant="primary" block style="background-color: #7289da;" @click="loginDiscord">
          <b-spinner small v-if="discordLoading"></b-spinner>
          <b-icon v-else icon="discord" font-scale="1.2"></b-icon> Login via Discord
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import { auth } from "../firebase";
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";

export default {
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      loginError: false,
      discordLoading: false
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.$router.push("/home"); // Redireciona para a pagina do perfil
      } catch (error) {
        console.error(error);
        this.loginError = true;
      } finally {
        this.isLoading = false;
      }
    },
    loginDiscord() {
      const clientId = '1131273258583457815'; 
      console.log(process.env.VUE_APP_BASE_URL)
      const redirectUri = encodeURIComponent(process.env.VUE_APP_BASE_URL + '/login');  // substitua por sua uri de redirecionamento
      const scope = encodeURIComponent('identify email guilds');
      window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;
    },
    async handleDiscordCallback() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        this.discordLoading = true;
        try {
          const response = await axios.post(process.env.VUE_APP_API_URL + '/api/discordAuth', { code });
          console.log(response)
          const { accessToken: firebaseToken } = response.data;
          await signInWithCustomToken(auth, firebaseToken);
          this.$router.push('/home');
        } catch (error) {
          console.error(error);
          this.loginError = true;
        } finally {
          this.discordLoading = false;
        }
      }
    }
  },
  mounted() {
    this.handleDiscordCallback();
  },
};
</script>

<style scoped>
.login-title{
font-weight: 700;
}
</style>
