<template>
  <div v-if="selectedGroup" class="list-m">
    <div class="fixed-header sob d-flex flex-row align-items-center">
      <b-avatar :src="selectedGroup.avatar" class="mr-3"></b-avatar>
      <h2 class="group-name-message">{{ selectedGroup.name }}</h2>
      <span class="id-group">{{selectedGroup.id}}</span>
    </div>
    <div
      class="body-chat overflow-auto d-flex flex-column"
      ref="messageContainer"
      @scroll="handleScroll"
    >
      <button
        v-if="showScrollDownButton"
        @click="scrollToBottom"
        class="scroll-button"
      >
        Rolar para baixo
      </button>
      <div v-if="isLoadingMessages" class="skeleton-chat">
      <MessageSkeleton></MessageSkeleton>
      
      
      </div>
      <vue-infinite-loading
        v-if="!isLoadingMessages"
        direction="top"
        @infinite="fetchGroupMoreMessages"
      ></vue-infinite-loading>
      <MessageView
        v-for="message in formattedMessages.slice().reverse()"
        :key="message.messageId"
        class=""
        :name="message.author.pushname"
        :number="message.author.id"
        :content="message.content"
        :type="message.type"
        :date="formatDate(message.date)"
        media=""
        reactions=""
        :isFirstMessageAuthor="message.isFirstMessageOfAuthor"
        :avatar="message.author.avatar"
        :color="message.author.color"
        :sentiment="message.sentimentScore"
        :path="message.path"
      ></MessageView>
    </div>

    <div class="input-area sob">
      <b-input-group class="d-flex flex-row align-items-center">
        <b-form-input
          placeholder="Digite sua mensagem aqui..."
          v-model="newMessage"
          class="input-sob mr-2"
        ></b-form-input>

        <b-input-group-append>
          <b-icon
            icon="plus-circle-fill"
            font-scale="1.5"
            style="color: var(--neutros-400)"
            @click="sendNewMessage"
          >
          </b-icon>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>

  <div v-else>
    <p>Selecione um grupo para visualizar as mensagens</p>
  </div>
</template>
<script>
import axios from "axios";
import { auth } from "../firebase";
import MessageView from "./MessageView.vue";
import MessageSkeleton from "./MessageSkeleton.vue"
import InfiniteLoading from "vue-infinite-loading";
import { format } from "date-fns";
import io from "socket.io-client";

export default {
  props: ["selectedGroupss"],
  watch: {
    selectedGroupss() {
      console.log("teste");
      this.fetchGroupMessages(this.selectedGroupss);
    },
  },
  components: {
    MessageView,
    MessageSkeleton,
    "vue-infinite-loading": InfiniteLoading,
  },
  data() {
    return {
      selectedGroup: "",
      messages: [],
      messageCursor: null,
      authors: {}, // adicionado
      isLoadingMessages: true,
      limit: 20,
      newMessage: "",
      showScrollDownButton: false,
    };
  },
  computed: {
    formattedMessages() {
      return this.messages.map((message, index, allMessages) => {
        const isFirstMessage = index === 0;
        const isLastMessage = index === allMessages.length - 1;
        const isFirstAfterOtherAuthor =
          !isLastMessage && message.author !== allMessages[index + 1].author;
        const isFirstMessageOfAuthor =
          isFirstMessage || isFirstAfterOtherAuthor;
        return {
          ...message,
          isFirstMessage,
          isFirstAfterOtherAuthor,
          isFirstMessageOfAuthor,
        };
      });
    },
  },
  created() {
    this.socket = io(process.env.VUE_APP_API_URL); // Substitua por seu endereço do servidor.

    this.socket.on("groups-update", ({ group }) => {
      console.log(group.id);
      console.log(this.selectedGroup.id);

      if (group.id === this.selectedGroup.id) {

        if(group.path){
          
          
            (async () => { // Função assíncrona anônima
            try {
              const token = await auth.currentUser.getIdToken();
              console.log(token)
              // Substitua 'http://sua-api.com/generate-url' pelo endereço correto
              const response = await axios.get(`${process.env.VUE_APP_API_URL}/generate-url?messageId=${group.messageId}`,{headers: { Authorization: `Bearer ${token}` }});

              
              // Supondo que a nova URL esteja na propriedade 'newPath' do objeto retornado
              const newPath = response.data.url;
              
              // Atualize o 'path'
              group.path = newPath;
            } catch (error) {
              console.error("Erro ao recuperar o novo path:", error);
            }
          })();
        }
        // Adicione a mensagem no início da lista de mensagens
        this.messages.unshift(group);

        if (this.isScrolledToBottom()) {
          this.readGroup();
          this.$nextTick(async () => {
            this.scrollToBottom();
          });
          1;
        }
      }
    });
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  methods: {
    isScrolledToBottom() {
      const container = this.$refs.messageContainer;
      return (
        container.scrollHeight - container.scrollTop === container.clientHeight
      );
    },

    handleScroll() {
      const container = this.$refs.messageContainer;
      const isScrolledToBottom =
        container.scrollHeight - container.scrollTop === container.clientHeight;
      if (!isScrolledToBottom) {
        this.showScrollDownButton = true;
      } else {
        this.showScrollDownButton = false;
      }
    },
    scrollToBottom() {
      console.log("scroll");
      this.$refs.messageContainer.scrollTop =
        this.$refs.messageContainer.scrollHeight;

      this.showScrollDownButton = false;
    },

    formatDate(timestamp) {
      if (!timestamp) return "";

      const now = new Date();
      const messageDate = new Date(timestamp);
      const diffInMilliseconds = now.getTime() - messageDate.getTime();

      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const oneHourInMilliseconds = 60 * 60 * 1000;

      if (
        diffInMilliseconds < oneDayInMilliseconds &&
        diffInMilliseconds >= 0
      ) {
        if (diffInMilliseconds < oneHourInMilliseconds) {
          return format(messageDate, "HH:mm");
        } else {
          return format(messageDate, "HH:mm");
        }
      } else if (
        diffInMilliseconds >= oneDayInMilliseconds &&
        diffInMilliseconds < 2 * oneDayInMilliseconds
      ) {
        return "Ontem";
      } else {
        return format(messageDate, "dd/MM/yyyy");
      }
    },
    getRandomColor() {
      const colors = [
        "#F44336", // Vermelho
        "#E91E63", // Rosa
        "#9C27B0", // Roxo
        "#673AB7", // Roxo Escuro
        "#3F51B5", // Índigo
        "#2196F3", // Azul
        "#03A9F4", // Azul Claro
        "#00BCD4", // Ciano
        "#009688", // Verde Azulado
        "#4CAF50", // Verde
        "#8BC34A", // Verde Claro
        "#CDDC39", // Lima
        "#FFEB3B", // Amarelo
        "#FFC107", // Ambar
        "#FF9800", // Laranja
      ];
      let color = colors[Math.floor(Math.random() * colors.length)];
      return color;
    },
    async fetchGroupMessages(group) {
      console.log(group);
      this.selectedGroup = group;
      this.messages = [];
      this.messageCursor = null;
      this.isLoadingMessages = true;

      const z = this.selectedGroup.id

      const token = await auth.currentUser.getIdToken();
      const safeGroupId = this.selectedGroup.id.replace(
        /%at%|%underline%|%dot%/g,
        function (match) {
          if (match === "%at%") return "@";
          else if (match === "%underline%") return "_";
          else if (match === "%dot%") return ".";
        }
      );

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/groups/${safeGroupId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              limit: this.limit,
              startAfterDate: this.messageCursor,
            },
          }
        );

        if (response.status === 200 & z === this.selectedGroup.id) {
          const { messages, nextCursor } = response.data;

          const authorIdsToFetch = [
            ...new Set(messages.map((m) => m.author)),
          ].filter((id) => !this.authors[id]);

          if (authorIdsToFetch.length > 0) {
            const authorsResponse = await axios.post(
              `${process.env.VUE_APP_API_URL}/api/getContacts`,
              { authorIds: authorIdsToFetch },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            if (authorsResponse.status === 200) {
              for (let author of authorsResponse.data) {
                author.color = this.getRandomColor();
                this.authors[author.id] = author;
              }
            }
          }

          for (let message of messages) {
            message.author = this.authors[message.author] || {
              id: message.author,
              name: "Desconhecido",
            };
          }

          this.messages = [...this.messages, ...messages];
          this.messageCursor = nextCursor;
          this.isLoadingMessages = false;

this.$nextTick(() => {
  const container = this.$refs.messageContainer;
  const images = container.querySelectorAll('img');
  let loadedImages = 0;

  if (images.length === 0) {
    // Se não houver imagens, role para o fundo imediatamente
    container.scrollTop = container.scrollHeight;
    return;
  }

  images.forEach((img) => {
    img.addEventListener('load', () => {
      loadedImages++;
      if (loadedImages === images.length) {
        container.scrollTop = container.scrollHeight;
      }
    });
  });
});
          
        }
      } catch (err) {
        console.error(err);
      }
    },
    async readGroup() {
      const token = await auth.currentUser.getIdToken();
      try {
        console.log("comecou a ler");
        console.log(this.selectedGroup.id);
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/readGroup`,
          {
            groupId: this.selectedGroup.id, // aqui estamos colocando o groupId no corpo da requisição
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Erro ao marcar o grupo como lido:", error);
      }
    },

    async fetchGroupMoreMessages($state) {
      if (!this.selectedGroup) {
        $state.complete();
        return;
      }

      const token = await auth.currentUser.getIdToken();
      const safeGroupId = this.selectedGroup.id.replace(
        /%at%|%underline%|%dot%/g,
        function (match) {
          if (match === "%at%") return "@";
          else if (match === "%underline%") return "_";
          else if (match === "%dot%") return ".";
        }
      );

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/groups/${safeGroupId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              limit: this.limit,
              startAfterDate: this.messageCursor,
            },
          }
        );

        if (response.status === 200) {
          const { messages, nextCursor } = response.data;

          const authorIdsToFetch = [
            ...new Set(messages.map((m) => m.author)),
          ].filter((id) => !this.authors[id]);

          if (authorIdsToFetch.length > 0) {
            const authorsResponse = await axios.post(
              `${process.env.VUE_APP_API_URL}/api/getContacts`,
              { authorIds: authorIdsToFetch },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            if (authorsResponse.status === 200) {
              for (let author of authorsResponse.data) {
                author.color = this.getRandomColor();
                this.authors[author.id] = author;
              }
            }
          }

          for (let message of messages) {
            message.author = this.authors[message.author] || {
              id: message.author,
              name: "Desconhecido",
            };
          }

          if (nextCursor) {
            this.messages = [...this.messages, ...messages];
            this.messageCursor = nextCursor;
            $state.loaded();
          } else {
            this.messages = [...this.messages, ...messages];
            $state.complete();
          }
        }
      } catch (err) {
        console.error(err);
        $state.error();
      }
    },
    async sendNewMessage() {
      if (this.newMessage.trim() !== "") {
        // Código para enviar a mensagem...
        this.newMessage = "";
      }
    },
  },
};
</script>
<style>
.list-m {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  border: solid 1px var(--neutros-800);
}

.container-chat {
  border-radius: 5px 5px 5px 5px;
  border: 1px solid var(--neutros-800);
  padding-left: 16px;
}

.body-chat {
  padding: 32px 64px;
}

.fixed-header {
  padding: 12px 16px;
  border-radius: 5px 5px 0 0;
  border: solid 0px;
}

.input-area {
  border-radius: 0 0 5px 5px;
  padding: 12px 16px;
}

.input-sob {
  background-color: var(--special-sob);
  border-radius: 5px;
  border: 1px solid var(--neutros-800);
}

.scroll-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.id-group{
  color:white;
}

</style>