<template>
    <div
      class="group-card sob"
      @click="emitFetchGroupMessages" 
      :class="{ 'active-group': isActive }"
    >
      <div class="group-info">
        <b-avatar :src="avatar" class="mr-3"></b-avatar>

        <div class="d-flex flex-column">
          <span class="group-name-card">{{ name }}</span>
          <span class="group-last-m-card">{{ lastMessageContent }}</span>
        </div>
      </div>

      <div class="group-status">
        <span class="group-hour-last-card">{{ date }}</span>
        <b-badge v-if="!isRead" class="new-badge" pill>1</b-badge>
      </div>
    </div>
</template>

<script>
export default {
    props: ['name', 'lastMessageContent', 'date', 'avatar', 'type', 'id', 'isActive', 'read'],
    data() {
      return {
        isRead: this.read
      };
    },
    methods: {
      emitFetchGroupMessages() {
        this.$emit('fetchGroupMessages', this.id); // Emitindo o evento com o ID do grupo
        this.isRead = true;
      }
    }   
}
</script>
<style>
.group-card {
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: stretch;
  margin-bottom: 8px;
  justify-content: space-between;
  transition: 0.3s;
  
}


.active-group {
      border: 1px solid var(--neutros-500);
}

.group-card:hover {
    border: 1px solid var(--neutros-500);
    cursor: pointer;
  
}

.group-name-message {
  color: var(--neutros-200);
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}
.group-name-card {
  color: var(--neutros-200);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
}
.group-last-m-card {
  font-size: 12px;
  font-weight: 400;
  color: var(--neutros-400);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
}
.group-hour-last-card {
  font-size: 12px;
  font-weight: 400;
  color: var(--neutros-400);
}

.group-info{
  display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}

.group-status{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
}

.new-badge{
  font-weight: 500 !important;
  font-size: 5px !important;
  color: var(--orange-hapo)!important;
  background: var(--orange-hapo) !important;
}
</style>