<template>
  <div class="audio-player d-flex align-items-center">
    <button class="btn btn-primary botaoPlayer" @click="togglePlay">
      <i :class="isPlaying ? 'bi-pause-fill' : 'bi-play-fill'"></i>
    </button>
    <input type="range" class="custom-range ml-3" v-model="currentTime" @input="setTime" :max="duration" />
    <div class="ml-3">{{ currentTimeFormatted }} / {{ durationFormatted }}</div>
  </div>
</template>

<script>
export default {
  props: ['srcAudio'],
  data() {
    return {
      audio: null,
      isPlaying: false,
      currentTime: 0,
      duration: 0,
    };
  },
  computed: {
    currentTimeFormatted() {
      return this.formatTime(this.currentTime);
    },
    durationFormatted() {
      return this.formatTime(this.duration);
    },
  },
  watch: {
    srcAudio() { // Atenção: corrigi de 'src' para 'srcAudio' para assistir à prop correta
      this.loadAudio();
    },
  },
  methods: {
    loadAudio() {
      this.audio = new Audio(this.srcAudio);
      this.audio.addEventListener('loadedmetadata', () => {
        this.duration = this.audio.duration;
      });
      this.audio.addEventListener('timeupdate', () => {
        this.currentTime = this.audio.currentTime;
      });
    },
    togglePlay() {
      if (this.isPlaying) {
        this.audio.pause();
      } else {
        this.audio.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    setTime() {
      this.audio.currentTime = this.currentTime;
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${String(seconds).padStart(2, '0')}`;
    },
  },
  mounted() {
    this.loadAudio();
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
    }
  },
};
</script>
<style>
.audio-player {
  display: flex;
  align-items: center;
}

.botaoPlayer{
    background-color: var(--orange-hapo) !important;
    border-color: var(--orange-hapo) !important;
}
</style>
