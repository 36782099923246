<template>
  <div class="group-list">
    <GroupView
      @fetchGroupMessages="emitToGrandParent"
      v-for="group in orderedGroups"
      :key="group.id"
      :name="group.name"
      :date="formatDate(group.lastMessageDate)"
      :avatar="group.avatar"
      :lastMessageContent="group.lastMessageContent"
      :type="group.type"
      :id="group.id"
      :isActive="group.id === activeGroupId"
      :read="group.read"
    >
    </GroupView>

    <vue-infinite-loading
      @infinite="fetchGroups"
    ></vue-infinite-loading>
  </div>
</template>

<script>
import GroupView from "./GroupView.vue";
import InfiniteLoading from "vue-infinite-loading";
import axios from "axios";
import { auth } from "../firebase";
import io from "socket.io-client";
import { format } from "date-fns";

let cancelTokenSource;

export default {
  components: {
    GroupView,
    "vue-infinite-loading": InfiniteLoading
  },
  data(){
    return{
        groups: [],
        limit: 10,
        groupCursor:null,
        activeGroupId: null
    }
  },
  computed: {
    orderedGroups() {
      return [...this.groups].sort(
        (a, b) => b.lastMessageDate - a.lastMessageDate
      );
    }
  },
      created() {
    this.socket = io(process.env.VUE_APP_API_URL); // Substitua por seu endereço do servidor.

    this.socket.on("groups-update", ({ group }) => {
      console.log(group);
      console.log("entrouu");
      const index = this.groups.findIndex((g) => g.id === group.id);
      console.log(index);
      if (index > -1) {
        this.$set(this.groups, index, group);
        console.log("modi: " + group);
      } else {
        this.groups = [...this.groups, group];
        console.log("added: " + group);
      }
    });
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  methods: {
    emitToGrandParent(valueFromNeto) {
      this.activeGroupId = valueFromNeto;
      this.$emit('toGrandParent', this.groups.find((group) => group.id === valueFromNeto));
    },
    
    async fetchGroups($state) {

      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled due to new request.');
      }

      // Cria um novo CancelToken
      cancelTokenSource = axios.CancelToken.source();

      console.log(cancelTokenSource)
      const token = await auth.currentUser.getIdToken();



      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/groups?cursor=${this.groupCursor}&limit=10`,
          { 
            headers: { Authorization: `Bearer ${token}` },
            cancelToken: cancelTokenSource.token
          
          }
        );

        if (response.data.data.length) {
          const newGroups = response.data.data.filter(
            (group) =>
              !this.groups.some(
                (existingGroup) => existingGroup.id === group.id
              )
          );
          this.groups = [...this.groups, ...newGroups];
          this.groupCursor = response.data.cursor;
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (err) {
if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          console.error(err);
          $state.error();
        }
      }
    },
formatDate(timestamp) {
      if (!timestamp) return "";

      const now = new Date();
      const messageDate = new Date(timestamp);
      const diffInMilliseconds = now.getTime() - messageDate.getTime();

      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const oneHourInMilliseconds = 60 * 60 * 1000;

      if (
        diffInMilliseconds < oneDayInMilliseconds &&
        diffInMilliseconds >= 0
      ) {
        if (diffInMilliseconds < oneHourInMilliseconds) {
          return format(messageDate, "HH:mm");
        } else {
          return format(messageDate, "HH:mm");
        }
      } else if (
        diffInMilliseconds >= oneDayInMilliseconds &&
        diffInMilliseconds < 2 * oneDayInMilliseconds
      ) {
        return "Ontem";
      } else {
        return format(messageDate, "dd/MM/yyyy");
      }
    },
    fetchGroupMessages(groupId) {
      this.$emit('fetchGroupMessages', groupId);
    }
  }
};
</script>

<style scoped>
.group-list {
  max-height: 100%;
  overflow-y: auto;
  padding-right: 16px;
}
</style>
