<template>
  <PageWrapper pageTitle="Monitoramento dos Grupos">
    <h1 class="mb-4">IA Análise de Grupos</h1>
    <div class="mb-4">
      <div v-for="(message, index) in messages" :key="index"
           class="message p-3 rounded mb-2"
           :class="{'bg-danger text-white': message.sentimentScore < 0, 'bg-primary text-white': message.sentimentScore >= 0}">
           <div class="group font-weight-bold">Grupo: {{message.group}}</div>
           <div class="author">Autor: {{message.author}}</div>
           <div class="date">Data: {{message.date}}</div>
           <div class="content mt-2">{{message.content}}</div>
      </div>
    </div>
    <b-alert variant="danger" v-model="alert.show" dismissible>{{alert.message}}</b-alert>
  </PageWrapper>
</template>

<script>
import PageWrapper from '../components/PageWrapper.vue';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";

export default {
  components: {
    PageWrapper,
  },
  data() {
    return {
      messages: [],
      alert: { show: false, message: '' }
    };
  },
  async mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Get Firebase token
        const token = await getIdToken(currentUser);
        this.connect(token);
      } else {
        // usuário não está logado
      }
    });
  },
  methods: {
    connect(token) {
      var socket = new WebSocket(`ws://186.215.120.106:8080/?token=${token}`);
      socket.onopen = () => {
        console.log("Connection established");
        this.alert = { show: true, message: "Conexão estabelecida com o servidor WebSocket." };
        setTimeout(() => this.alert.show = false, 2000);
      };
      socket.onmessage = (event) => {
        console.log(`Data received from server: ${event.data}`);
        var data = JSON.parse(event.data);
        this.messages.unshift(data);
      };
      socket.onerror = (error) => {
        console.log(`WebSocket error: ${error}`);
        this.alert = { show: true, message: `Erro no WebSocket: ${error}` };
      };
      socket.onclose = (event) => {
        if (event.wasClean) {
          console.log(`Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
        } else {
          console.log('Connection died');
        }
        setTimeout(() => this.connect(token), 10000);
        this.alert = { show: true, message: "Conexão perdida com o servidor WebSocket. Tentando reconectar em 10 segundos..." };
      };
    }
  }
};
</script>

<style scoped>
.message {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  line-height: 1.5;
  max-width: 60%;
  opacity: 0.8;
}
</style>
