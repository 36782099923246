import { render, staticRenderFns } from "./MessageSkeleton.vue?vue&type=template&id=16d679dd&scoped=true&"
var script = {}
import style0 from "./MessageSkeleton.vue?vue&type=style&index=0&id=16d679dd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d679dd",
  null
  
)

export default component.exports